import React from "react";
import MaskedInput from "react-input-mask";
import { FormControl, OutlinedInput, InputLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import isDC from "../../../types/isDC"

const styles = theme => ({
    root: {
        "&$focused $notchedOutline": {
            borderColor: isDC ? '#3f51b5' : '#009739',
            color: isDC ? '#3f51b5' : '#009739',
        }
    },
    focused: {},
    notchedOutline: {}
});

function MaskedInputTelefone(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

function InputTelefone(props) {
    const { inputRef, ...other } = props;
    let classNameLabel = 'label-telefone'

    if (!isDC) classNameLabel = classNameLabel.concat(' pp')
    return (
        <FormControl error={props.erros['telefone']}>
            <InputLabel className={classNameLabel} htmlFor="telefone-input">Telefone
                                            *</InputLabel>
            <OutlinedInput
                label={"Telefone:"}
                value={props.value}
                onChange={props.onChangeTelefone}
                name="telefone"
                id="telefone-input"
                inputComponent={MaskedInputTelefone}
                classes={props.classes}
                error={props.erros['telefone']}
                required={true}
            />
        </FormControl>
    )
}

export default withStyles(styles)(InputTelefone);