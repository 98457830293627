import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';

import Home from "./paginas/home/home";
import Agendamento from "./paginas/agendamento/agendamento";
import Resumo from "./paginas/resumo/resumo";
import ScrollToTop from "./componentes/scrollToTop/scrollToTop";
import Admin from './paginas/admin/admin';

import './App.scss';

function App() {
    return (
        <>
            <div className="App">
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Router>
                        <ScrollToTop>
                            <Switch>
                                <Route path="/agendamento">
                                    <Agendamento/>
                                </Route>
                                <Route path="/resumo">
                                    <Resumo/>
                                </Route>
                                <Route path="/admin">
                                    <Admin/>
                                </Route>
                                <Route path="/">
                                    <Home/>
                                </Route>
                            </Switch>
                        </ScrollToTop>
                    </Router>
                </MuiPickersUtilsProvider>
            </div>
        </>
    );
}

export default App;
