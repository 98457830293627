import React from "react";
import { FormControl, MenuItem, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import TipoDuvidaEnum from "../../../enums/tipoDuvida";
import isDC from "../../../types/isDC"

import './selectDuvida.scss'

const useStyles = makeStyles({
    root: {
        "& .MuiInputLabel-root.Mui-focused": {
            color: isDC ? '#3f51b5' : '#009739'
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: isDC ? '#3f51b5' : '#009739'
        }
    }
});

const SelectDuvida = (props) => {
    const classes = useStyles();

    return (
        <>
            <FormControl className="form-control-duvida" variant="outlined" error={props.erro} required={true}>
                <TextField
                    labelId="select-horario-label"
                    id="select-horario"
                    value={props.value}
                    onChange={props.onChangeTipoDuvida}
                    label="Como podemos te ajudar?"
                    variant="outlined"
                    className={classes.root}
                    select
                >
                    <MenuItem value="" disabled>Como podemos te ajudar?</MenuItem>
                    <MenuItem value={TipoDuvidaEnum.ORIENTACAO_RECEITAS}>
                        {TipoDuvidaEnum.LABELS[TipoDuvidaEnum.ORIENTACAO_RECEITAS]}
                    </MenuItem>
                    <MenuItem value={TipoDuvidaEnum.REVISAO_RECEITAS}>
                        {TipoDuvidaEnum.LABELS[TipoDuvidaEnum.REVISAO_RECEITAS]}
                    </MenuItem>
                    <MenuItem value={TipoDuvidaEnum.DUVIDAS_GERAIS}>
                        {TipoDuvidaEnum.LABELS[TipoDuvidaEnum.DUVIDAS_GERAIS]}
                    </MenuItem>
                    <MenuItem value={TipoDuvidaEnum.ORIENTACOES_SINTOMAS}>
                        {TipoDuvidaEnum.LABELS[TipoDuvidaEnum.ORIENTACOES_SINTOMAS]}
                    </MenuItem>
                    <MenuItem value={TipoDuvidaEnum.ORIENTACOES_EQUIPAMENTOS}>
                        {TipoDuvidaEnum.LABELS[TipoDuvidaEnum.ORIENTACOES_EQUIPAMENTOS]}
                    </MenuItem>
                    <MenuItem value={TipoDuvidaEnum.OUTROS}>
                        {TipoDuvidaEnum.LABELS[TipoDuvidaEnum.OUTROS]}
                    </MenuItem>
                </TextField>
            </FormControl>
        </>
    )
}

export default SelectDuvida;