import React from "react";
import { KeyboardDatePicker, } from '@material-ui/pickers';
import { makeStyles } from "@material-ui/core/styles";

import isDC from "../../../types/isDC"

const useStyles = makeStyles({
    root: {
        "& .MuiInputLabel-root.Mui-focused": {
            color: isDC ? '#3f51b5' : '#009739'
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: isDC ? '#3f51b5' : '#009739'
        }
    }
});

function InputData(props) {
    const classes = useStyles();
    return (
        <KeyboardDatePicker
            variant="outlined"
            inputVariant={"outlined"}
            disableToolbar
            format="DD/MM/yyyy"
            margin="normal"
            id="data"
            label="Data"
            value={props.value}
            className={classes.root}
            onChange={props.onChangeData}
            KeyboardButtonProps={{
                'aria-label': 'change date',
            }}
            required={true}
            error={props.erros['data']}
        />
    )
}

export default InputData;