import React from "react";
import {useHistory} from 'react-router-dom';

import TituloPagina from "../../../componentes/titulo-pagina/tituloPagina";
import Botao from "../../../componentes/botao/botao";

import inicio from "../../../imagens/inicio-768x659.png";
import logoDcCare from '../../../imagens/logo-dc-care.png'

const SecaoInicio = () => {
    const history = useHistory()
    return (
        <section id="inicio">
            <img className="logo-dc-care" src={logoDcCare} alt="" />
            <div className="container-inicio">
                <div>
                    <TituloPagina>
                        <div className="title-atendimento-gratuito">Atendimento gratuito</div>
                        <div>FARMACÊUTICO DIGITAL</div>
                    </TituloPagina>
                    <span className="descricao-inicio">
                        <p>Converse com nosso farmacêutico online e tenha toda a atenção necessária sem sair de casa, como se estivesse em uma de nossas farmácias.</p>
                    </span>
                    <div className="footer">
                        <Botao
                            tipo={'primario'} href={'#sobre'}
                        >
                            COMO FUNCIONA?
                        </Botao>
                        <Botao
                            tipo={'secundario'}
                            onClick={() => {
                                history.push(`agendamento`)
                            }}
                        >
                            QUERO SER ATENDIDO(A)
                        </Botao>
                    </div>
                </div>
                <img className="imagem-inicio" src={inicio} alt=""/>
            </div>
        </section>
    )
}

export default SecaoInicio