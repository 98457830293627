import React from "react";

import './tituloPagina.scss'

const TituloPagina = (props) => {
    var classesLabelTitle = "label-title";
    if (props.classes) classesLabelTitle = classesLabelTitle.concat(` ${props.classes}`)

    return (
        <div className="titulo-pagina">
            <div>
                {props.children.map ? props.children.map((item, index) => {
                    return (
                        <span key={`titulo-${index}`} className={classesLabelTitle}>{item}</span>
                    )
                }) : (
                    <>
                        <span className={classesLabelTitle}>{props.children}</span>
                        {props.subtitulo &&(<span className="label-subtitle">{props.subtitulo}</span>)}
                    </>
                )}
            </div>
        </div>
    )
}

export default TituloPagina