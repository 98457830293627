import React, { useState } from 'react'
import moment from "moment";
import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import SelectHorario from "./selectHorario/selectHorario";
import Botao from "../../componentes/botao/botao";
import TituloPagina from "../../componentes/titulo-pagina/tituloPagina";
import InputTelefone from "./inputTelefone/inputTelefone";
import InputData from "./inputData/inputData";
import ModalConfirmarAgendamento from "./modalConfirmarAgendamento/modalConfirmarAgendamento";
import SelectDuvida from "./selectDuvida/selectDuvida";
import ModalOutrosAssuntos from "../home/secaoInicio/modalOutrosAssuntos/modalOutrosAssuntos";
import InputTipoContato from "./inputTipoContato/inputTipoContato";

import { criarAgendamento } from "../../services/agendamento.service";
import TipoDuvidaEnum from "../../enums/tipoDuvida";
import TipoAtendimentoEnum from "../../enums/tipoAtendimento";
import isDC, { ORIGEM } from "../../types/isDC"

import './agendamento.scss'

const styles = {
    cssLabel: {
        '&$cssFocused': {
            color: isDC ? '#3f51b5' : '#009739',
        },
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: isDC ? '#3f51b5' : '#009739',
        },
    },
    root: {
        "& $notchedOutline": {
        },
        "&:hover $notchedOutline": {
        },
        "&$focused $notchedOutline": {
            borderColor: isDC ? '#3f51b5' : '#009739',
        }
    },
    cssFocused: {},
    notchedOutline: {},
}

const Agendamento = (props) => {
    const history = useHistory()

    const classesPP = !isDC ? 'pp' : undefined

    const [modalConfirmarAgendamentoVisivel, setModalConfirmarAgendamentoVisivel] = useState(false)
    const [modalOutrosAssuntosAberto, setModalOutrosAssuntosAberto] = useState(false)

    const [tipoDuvida, setTipoDuvida] = useState('')
    const [tipoContato, setTipoContato] = useState(TipoAtendimentoEnum.CHAMADA_TELEFONICA)
    const [nome, setNome] = useState('')
    const [telefone, setTelefone] = useState('')
    const [data, setData] = useState(moment())
    const [hora, setHora] = useState('')

    const [erros, setErros] = useState({})

    const onChangeTipoDuvida = (e) => {
        if (e.target.value === TipoDuvidaEnum.OUTROS) {
            abrirModalOutrosAssuntos()
        }

        setTipoDuvida(e.target.value)
        if (e.target.value) {
            removerErroDoCampo('tipoDuvida')
        }
    }

    const onChangeTipoContato = (e) => {
        setTipoContato(e.target.value)

        if (e.target.value) {
            removerErroDoCampo('tipoContato')
        }
    }

    const onChangeNome = (e) => {
        setNome(e.target.value)
        removerErroDoCampo('nome')
    }

    const onChangeTelefone = (e) => {
        setTelefone(e.target.value)
        if (e.target.value && e.target.value !== "(__) _____-____") {
            removerErroDoCampo('telefone')
        }
    }

    const onChangeData = (e) => {
        setData(e)
    }

    const onChangeHorario = (e) => {
        setHora(e.target.value)
        if (e.target.value) {
            removerErroDoCampo('hora')
        }
    }

    const salvar = () => {
        if (dadosValidados()) {
            setModalConfirmarAgendamentoVisivel(true)
        }
    }

    const dadosValidados = () => {
        const errosValidados = {}

        if (!tipoDuvida) {
            errosValidados.tipoDuvida = true
        }

        if (!tipoContato) {
            errosValidados.tipoContato = true
        }

        if (!nome) {
            errosValidados.nome = true
        }

        if (!telefone) {
            errosValidados.telefone = true
        }

        if (!hora) {
            errosValidados.hora = true
        }

        if (!data) {
            errosValidados.data = true
        }

        setErros(errosValidados)
        return Object.keys(errosValidados).length === 0
    }

    const removerErroDoCampo = (campo) => {
        const errosSemOCampo = { ...erros }
        delete errosSemOCampo[campo]
        setErros(errosSemOCampo)
    }

    const onClickConfirmarAgendamento = () => {
        const dados = {
            nome: nome,
            telefone: telefone,
            data: data.format('DD/MM/YYYY'),
            hora: hora,
            tp_contato: tipoContato,
            tp_duvida: tipoDuvida,
            origem: ORIGEM
        }

        console.log(dados)
        criarAgendamento(dados).then((res) => {
            if (res.data.success) {
                localStorage.setItem('dados', JSON.stringify(dados));
                history.push('resumo')
            }
        }).catch(() => {
        })
    }


    const onClickCancelarAgendamento = () => {
        setModalConfirmarAgendamentoVisivel(false)
    }

    const fecharModalOutrosAssuntos = () => {
        setModalOutrosAssuntosAberto(false)
    }

    const abrirModalOutrosAssuntos = () => {
        setModalOutrosAssuntosAberto(true)
    }

    const onClickProsseguirConversaWhatsApp = () => {
        if (tipoDuvida) {
            abrirConverWhatsApp()
        } else {
            setErros({
                tipoDuvida: true
            })
        }
    }

    const abrirConverWhatsApp = () => {
        const message = `Iniciar atendimento ${isDC ? 'DC' : 'PP'}: ${TipoDuvidaEnum.LABELS[tipoDuvida]}`
        const numeroFilial = '5547992021687'
        window.open(`https://wa.me/${numeroFilial}?text=${message}`, "_blank")
    }

    return (
        <div className="container">
            <ModalConfirmarAgendamento onClose={onClickCancelarAgendamento} onSubmit={onClickConfirmarAgendamento}
                open={modalConfirmarAgendamentoVisivel} />
            <ModalOutrosAssuntos open={modalOutrosAssuntosAberto} onClose={fecharModalOutrosAssuntos} />

            {/* <div>
                <div className="container-page-title-agendamento">
                    <h1 className={`title-page-agendamento ${classesPP}`}>Atendimento Farmacêutico Digital</h1>
                    <p>O atendimento farmacêutico digital é um serviço da Farmácia Preço Popular que lhe oferece a comodidade de atendimento farmacêutico onde estiver. Conta com profissionais qualificados, sempre prontos para cuidar de você!</p>
                </div>
                <h2 className={`title-agendamento ${classesPP}`}>SERVIÇOS OFERTADOS</h2>

                <ul>
                    <li>Orientação de Receitas;</li>
                    <li>Revisão de Receitas;</li>
                    <li>Sanar dúvidas sobre medicamentos em geral, como posologia, reação adversa, modo de uso, entre outras;</li>
                    <li>Orientação sobre sintomas e indicação de medidas não farmacológicas;</li>
                    <li>Orientação sobre uso de equipamentos como: glicosimetro, aparelho de pressão, nebulizador, termômetro, entre outros.</li>
                </ul>

                <h2 className={`title-agendamento ${classesPP}`}>SERVIÇOS NÃO OFERTADOS NESSE CANAL</h2>

                <ul>
                    <li>Consulta de preços;</li>
                    <li>Disponibilidade de produtos;</li>
                    <li>Farmácia Popular do Brasil.</li>
                    <li>{`Para estes e outros assuntos entrar em contato via atendimento telefônico: ${isDC ? '0800 047 6262' : '0800 643 3031'} (Segunda a Sexta, das 8h às 20h)`}</li>

                </ul>

                <h2 className={`title-agendamento ${classesPP}`}>COMO FUNCIONA?</h2>

                <ul style={{ listStyleType: 'none' }}>
                    <li>
                        <h4>1. Escolha a melhor forma de atendimento</h4>
            Você pode escolher entre receber uma chamada por telefone, receber uma videochamada ou iniciar uma conversa via Whatsapp.
    </li>
                    <li>
                        <h4>2. Escolha o melhor momento</h4>
            Você pode escolher entre agendar um horário para atendimento ou iniciar uma conversa instantaneamente.
    </li>
                    <li>
                        <h4>3. Caso você escolha agendamento</h4>
            O farmacêutico irá entrar em contato no horário solicitado. O horário de atendimento é de Segunda a Sexta feira das 8h as 17:20hr.
    </li>
                </ul>

            </div> */}
            <section id="agendamento">
                {/* <TituloPagina classes={classesPP} subtitulo="Para agilizar o seu atendimento precisamos de algumas informações:">
                    AGENDAMENTO
                </TituloPagina> */}
                <h2 className={`title-agendamento ${classesPP} remove-margin-top`}>AGENDAMENTO</h2>
                <p className="subtitle-agendamento">Para agilizar o seu atendimento precisamos de algumas informações:</p>
                <div className="container-agendamento">

                    <form className="form-agendamento">
                        <SelectDuvida value={tipoDuvida} erro={erros['tipoDuvida']}
                            onChangeTipoDuvida={onChangeTipoDuvida} />
                        <InputTipoContato value={tipoContato} onChangeTipoContato={onChangeTipoContato}
                            erro={erros['tipoContato']} />

                        {tipoContato && tipoContato === TipoAtendimentoEnum.CONVERSA_POR_WHATSAPP && (
                            <div className="container-prosseguir-conversa-whatsapp">
                                <span>Iremos direcionar você para o aplicativo do WhatsApp. Para continuar, clique no botão abaixo.</span>
                                <Botao classes={classesPP} tipo={'secundario'} disabled={!tipoDuvida || tipoDuvida === TipoDuvidaEnum.OUTROS}
                                    onClick={onClickProsseguirConversaWhatsApp}>PROSSEGUIR</Botao>
                            </div>
                        )}

                        {tipoContato === '' || tipoContato !== TipoAtendimentoEnum.CONVERSA_POR_WHATSAPP && (
                            <>
                                <div className="form-field">
                                    <TextField
                                        variant="outlined"
                                        error={'nome' in erros}
                                        id="nome"
                                        label="Nome"
                                        defaultValue=""
                                        onChange={onChangeNome}
                                        InputLabelProps={{
                                            classes: {
                                                root: props.classes.cssLabel,
                                                focused: props.classes.cssFocused,
                                            },
                                        }}
                                        InputProps={{
                                            classes: {
                                                root: props.classes.cssOutlinedInput,
                                                focused: props.classes.cssFocused,
                                                notchedOutline: props.classes.notchedOutline,
                                            },
                                        }}
                                        required={true}
                                    />
                                </div>
                                <div className="form-field form-field-telefone">
                                    <InputTelefone value={telefone} onChangeTelefone={onChangeTelefone} erros={erros} />
                                </div>
                                <div className="form-field-group-datas">
                                    <div className="form-field-data">
                                        <InputData value={data} erros={erros} onChangeData={onChangeData} />
                                    </div>
                                    <div className="form-field-horario">
                                        <SelectHorario value={hora} onChangeHorario={onChangeHorario}
                                            error={erros['hora']} />
                                    </div>
                                </div>
                                <div className="footer">
                                    <Botao classes={classesPP} tipo={'secundario'} onClick={salvar} disabled={!tipoDuvida || tipoDuvida === TipoDuvidaEnum.OUTROS}>AGENDAR</Botao>
                                </div>
                            </>
                        )}
                    </form>
                </div>
            </section>
        </div>
    )
}

export default withStyles(styles)(Agendamento);