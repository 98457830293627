import React from 'react'

import SecaoInicio from "./secaoInicio/secaoInicio";
import SecaoSobre from "./secaoSobre/secaoSobre";
import Footer from '../../componentes/footer/footer';

import './home.scss'

const Home = () => {
    return (
        <div className="container">            
            <SecaoInicio />
            <SecaoSobre />            
            <Footer/>
        </div>
    )
}

export default Home