import React, { useEffect, useState } from 'react'

import TituloPagina from "../../componentes/titulo-pagina/tituloPagina";

import TipoDuvidaEnum from "../../enums/tipoDuvida";
import TipoAtendimentoEnum from "../../enums/tipoAtendimento";

import inicio from '../../imagens/inicio-768x659.png'

import './resumo.scss'

const Resumo = () => {
    const [dados, setDados] = useState({})

    useEffect(() => {
        obterDadosFormatados()
    }, [])

    const obterDadosFormatados = () => {
        const dadosLocalStorage = JSON.parse(localStorage.getItem('dados'))
        setDados(dadosLocalStorage)
    }

    return (
        <div className="container">
            <section id="resumo">
                <div className="container-resumo">
                    <div className="container-pagina">
                        <img className="imagem-resumo-responsiva" src={inicio} alt="" />
                        <TituloPagina
                            subtitulo="O seu atendimento foi agendado. Você receberá o contato de nosso farmacêutico na data e horário informados.">
                            CONFIRMAÇÃO
                    </TituloPagina>

                        {dados && (
                            <div className="container-dados-resumo">
                                <span className="titulo-resumo">Tipo de Atendimento:</span>
                                <span className="valor-resumo">{TipoDuvidaEnum.LABELS[dados.tp_duvida]}</span>
                                <span className="titulo-resumo">Forma de atendimento:</span>
                                <span className="valor-resumo">{TipoAtendimentoEnum.LABELS[dados.tp_contato]}</span>
                                <span className="titulo-resumo">Nome:</span>
                                <span className="valor-resumo">{dados.nome}</span>
                                <span className="titulo-resumo">Telefone:</span>
                                <span className="valor-resumo">{dados.telefone}</span>
                                <span className="titulo-resumo">Data:</span>
                                <span className="valor-resumo">{dados.data}</span>
                                <span className="titulo-resumo">Horário:</span>
                                <span className="valor-resumo">{dados.hora}</span>
                            </div>
                        )}

                        {/* <div className="footer-resumo">
                            <span className="aviso-footer">Ao clicar no botão ao lado, iremos redirecionar você para o site da Drogaria Catarinense.</span>
                            <Botao tipo='primario' onClick={redirecionarParaDC}>OK</Botao>
                        </div> */}
                    </div>
                </div>
                <img className="imagem-resumo" src={inicio} alt="" />
            </section>
        </div>
    )
}

export default Resumo