import React, { useState } from 'react'
import { TextField } from '@material-ui/core';
import Botao from '../../../componentes/botao/botao';

import './form-login.scss'
import { validarLogin } from '../../../services/login.service';

const FormLogin = () => {
    const [login, setLogin] = useState('')
    const [senha, setSenha] = useState('')

    const [erros, setErros] = useState({})

    const onChangeLogin = (e) => {
        setLogin(e.target.value)
    }

    const onChangeSenha = (e) => {
        setSenha(e.target.value)
    }

    const submit = () => {
        preencheuDadosDeLogin() && validarLogin(login, senha).then(res => {
            if (res.data.success) {
                if (res.data.data === null) {
                    setErros({ usuarioInvalido: true });
                } else {
                    localStorage.setItem('token-tf', '1')
                    window.location.reload()
                }
            } else {
                setErros({ usuarioInvalido: true });
            }
        }).catch(() => {
            setErros({ usuarioInvalido: true });
        })
    }

    const preencheuDadosDeLogin = () => {
        let erros = {}
        let temErros = false;
        if (!login) {
            erros.login = true;
            temErros = true;
        }
        if (!senha) {
            erros.senha = true;
            temErros = true;
        }
        setErros(erros);
        return !temErros;
    }

    const onKeyPress = (e) => {
        if (e.key === 'Enter') {
            submit()
        }
    }

    return (
        <div className="login-container">
            <div className="content">
                {'usuarioInvalido' in erros && erros['usuarioInvalido'] && (
                    <span className="erro-login">Problema ao realizar login.</span>
                )}
                <div className="form-field">
                    <TextField
                        variant="outlined"
                        error={'login' in erros && erros['login']}
                        id="login"
                        label="Login"
                        defaultValue=""
                        onChange={onChangeLogin}
                        onKeyPress={onKeyPress}
                        required={true}
                    />
                </div>
                <div className="form-field">
                    <TextField
                        variant="outlined"
                        error={'senha' in erros && erros['senha']}
                        id="senha"
                        label="Senha"
                        type="password"
                        defaultValue=""
                        onChange={onChangeSenha}
                        onKeyPress={onKeyPress}
                        required={true}
                    />
                </div>

                <div className="footer">
                    <Botao tipo={'secundario'} onClick={submit}>ENTRAR</Botao>
                </div>
            </div>
        </div>
    )
}

export default FormLogin