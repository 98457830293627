import React from "react";

import './botao.scss'

const Botao = (props) => {
    const linkProps = {
        href: props.href && !props.disabled ? props.href : undefined,
        className: props.className ? props.className : undefined
    }
    const spanProps = {
        onClick: props.onClick && !props.disabled ? props.onClick : undefined,
    }
    let classes = props.tipo === 'primario' ? 'botao-primario' : 'botao-secundario'
    if (props.classes) classes = classes.concat(` ${props.classes}`)

    return (
        <a {...linkProps} style={{'opacity': props.disabled ? '0.5' : '1'}}>
            <span className={`botao ${classes}`}
                  style={{'cursor': props.disabled ? 'not-allowed' : 'pointer'}}
                  {...spanProps}
            >{props.children}</span>
        </a>
    )
}

export default Botao