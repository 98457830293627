const TipoDuvidaEnum = Object.freeze({
    "ORIENTACAO_RECEITAS": '1',
    "REVISAO_RECEITAS": '2',
    "DUVIDAS_GERAIS": '3',
    "ORIENTACOES_SINTOMAS": '4',
    "ORIENTACOES_EQUIPAMENTOS": '5',
    "OUTROS": '6',
    "LABELS": {
        '1': '1 - Orientação de Receitas',
        '2': '2 - Revisão de Receitas',
        '3': '3 - Dúvidas sobre medicamentos em geral, como posologia, reação adversa, modo de uso, entre outras',
        '4': '4 - Orientação sobre sintomas e indicação de medidas não farmacológicas',
        '5': '5 - Orientação sobre uso de equipamentos como: glicosimetro, aparelho de pressão, nebulizador, termômetro, entre outros',
        '6': '6 - Consulta de preços / Disponibilidade de produtos / Farmácia Popular do Brasil',
    }
})
export default TipoDuvidaEnum