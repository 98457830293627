import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Botao from '../../../../componentes/botao/botao';

import TipoDuvidaEnum from '../../../../enums/tipoDuvida';
import TipoAtendimentoEnum from '../../../../enums/tipoAtendimento';

import './modalDetalheAgendamento.scss'

const ModalDetalheAgendamento = (props) => {
    const { onClose, open, agendamento } = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle className="modal-title">Detalhes do Agendamento</DialogTitle>
            <div className="modal-content-detalhar-agendamento">
                {agendamento && (
                    <div className="container-dados">
                        <span className="titulo-resumo">Origem:</span>
                        <span>{agendamento.origem && agendamento.origem.toUpperCase()}</span>
                        <span className="titulo-resumo">Tipo de Atendimento:</span>
                        <span>{TipoDuvidaEnum.LABELS[agendamento.tp_duvida]}</span>
                        <span className="titulo-resumo">Forma de atendimento:</span>
                        <span>{TipoAtendimentoEnum.LABELS[agendamento.tp_contato]}</span>
                        <span className="titulo-resumo">Nome:</span>
                        <span>{agendamento.nome}</span>
                        <span className="titulo-resumo">Telefone:</span>
                        <span>{agendamento.telefone}</span>
                        <span className="titulo-resumo">Data:</span>
                        <span>{agendamento.data}</span>
                        <span className="titulo-resumo">Horário:</span>
                        <span>{agendamento.hora}</span>
                    </div>
                )}

                <Botao tipo="secundario"
                    onClick={handleClose}>FECHAR</Botao>
            </div>
        </Dialog>
    );
}

export default ModalDetalheAgendamento