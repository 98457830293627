import React from 'react'
import Dialog from '@material-ui/core/Dialog';

import './modalOutrosAssuntos.scss'

const ModalOutrosAssuntos = (props) => {
    const {onClose, selectedValue, open} = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <div className="modal-content">
                <span>
                    Para estes e outros assuntos, você pode entrar em contato via atendimento telefônico:
                </span>
                <span>
                    <strong>0800 047 6262.</strong>
                </span>
                <span className="titulo-horario-de-atendimento">
                    Horário de Atendimento
                </span>
                <span>
                    Segunda a Sexta, das 8h às 20h.
                </span>
            </div>
        </Dialog>
    );
}

export default ModalOutrosAssuntos