import React from 'react'
import logoDcSimbolo from "../../imagens/dc-simbolo.png";
import logoFb from "../../imagens/icon_fb.png";
import logoInsta from "../../imagens/icon_insta.png";

import './footer.scss'

const Footer = () => window.location.pathname !== '/admin' && (
    <div className="app-footer">
        <div>
            <a href="https://drogariacatarinense.com.br"><img src={logoDcSimbolo} alt="" /></a>
            <p>
                DROGARIA CATARINENSE | Cia Latino Americana de Medicamentos
                | CNPJ: 84.683.481/0416-04 | Endereço: Rua Américo Brasiliense, 1958
                | São Paulo - SP | CEP: 04.715-004
            </p>
            <a href="https://www.facebook.com/drogariacatarinense/"><img className="logo-facebook" src={logoFb} alt="" /></a>
            <a href="https://www.instagram.com/drogariacatarinense"><img src={logoInsta} alt="" /></a>
        </div>
    </div>
)

export default Footer