import React, { useEffect, useState } from 'react'
import FormLogin from './login/form-login'
import Agendamentos from './agendamentos/agendamentos'

import './admin.scss'

const Admin = () => {
    const [realizouLogin, setRealizouLogin] = useState(false)
    const [verificouLogin, setVerificouLogin] = useState(false)

    useEffect(() => {
        const dadosLogin = localStorage.getItem('token-tf')
        if (dadosLogin) setRealizouLogin(true)
        setVerificouLogin(true)
    }, [])

    return verificouLogin && (
        <>
            {realizouLogin ? (
                <Agendamentos />
            ) : (
                    <FormLogin />
                )
            }
        </>
    )
}

export default Admin