import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import Botao from "../../../componentes/botao/botao";

import './modalConfirmarAgendamento.scss'

const ModalConfirmarAgendamento = (props) => {
    const {onClose, onSubmit, open} = props;

    const handleClose = () => {
        onClose();
    };

    const onClickProsseguir = () => {
        onSubmit()
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle className="modal-title">Podemos confirmar o agendamento para a data e hora
                informados?</DialogTitle>
            <div className="modal-content-confirmar-agendamento">
                <Botao tipo="secundario"
                       onClick={handleClose}>NÃO, QUERO VOLTAR!</Botao>
                <Botao tipo="primario" className={'botao-confirmar-agendamento'}
                       onClick={onClickProsseguir}>SIM, CONFIRMADO!</Botao>
            </div>
        </Dialog>
    );
}

export default ModalConfirmarAgendamento