import axios from 'axios'

export function validarLogin(matricula, senha) {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*'
    }

    var params = new URLSearchParams();
    params.append('token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoic2VydmljZWNhcmUiLCJjb21wYW55IjoiQ2lhIExhdGlubyBBbWVyaWNhbmEgZGUgTWVkaWNhbWVudG9zIiwic2l0ZSI6Imh0dHBzOlwvXC93d3cuY2xhbWVkLmNvbS5iciIsImlhdCI6MTYxMDk3MTczNiwiZXhwIjoxNzY4NzM4MTM2fQ.jVMWkx_SDZZ5BCyqI_QXIExDxvjJN2J-GNQp7DjGMdo');
    params.append('type', 'login');
    params.append('q', matricula);
    params.append('q2', window.btoa(senha));    

    return axios({
        method: 'get',
        url: 'https://ws.clamed.com.br:8080/sincroniza.php',        
        params: params,
        headers: headers
    });
}