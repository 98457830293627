import React, { useEffect } from "react";
import { useHistory } from 'react-router-dom';

import TituloPagina from "../../../componentes/titulo-pagina/tituloPagina";
import Botao from "../../../componentes/botao/botao";

import ligacao from "../../../imagens/ligacao.png";

const SecaoSobre = () => {
    const history = useHistory()

    useEffect(() => {
        window.addEventListener("load", function () {
            const imagemSobre = document.getElementById('imagem-sobre')
            const containerSobreContent = document.getElementById('container-tabela-sobre')
            imagemSobre.style.height = `${containerSobreContent.offsetHeight}px`
        });
    }, [])

    return (
        <section id="sobre">
            <div className="container-sobre">
                <TituloPagina>
                    <div>COMO FUNCIONA?</div>
                </TituloPagina>
                <div className="container-sobre-content">
                    <img id="imagem-sobre" className="imagem-agendamento" src={ligacao} alt="" />
                    <div id="container-tabela-sobre">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="card-1">
                                        <div className="step-card card-1">1</div>
                                    </td>
                                    <td className="card-content">
                                        <span
                                            className="descricao-sobre"><strong>Escolha a melhor forma de atendimento.</strong> Você
                                    pode escolher entre receber uma chamada por telefone, receber uma videochamada ou iniciar
                                    uma conversa via Whatsapp.</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="card-2">
                                        <div className="step-card card-2">2</div>
                                    </td>
                                    <td className="card-content">
                                        <span className="descricao-sobre"><strong>Escolha o melhor momento.</strong> Você pode escolher
                                    entre agendar um horário para atendimento ou iniciar uma conversa instantaneamente.</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="card-3">
                                        <div className="step-card">3</div>
                                    </td>
                                    <td className="card-content">
                                        <span className="descricao-sobre">Caso você escolha agendamento, o farmacêutico irá entrar em
                                    contato no horário solicitado.</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="footer">
                    <Botao tipo={'primario'} onClick={() => { history.push(`agendamento`) }}>QUERO SER ATENDIDO(A)</Botao>
                </div>
            </div>
        </section>
    )
}

export default SecaoSobre