const TipoAtendimentoEnum = Object.freeze({
    "CHAMADA_TELEFONICA": '1',
    "VIDEOCHAMADA_POR_WHATSAPP": '2',
    "CONVERSA_POR_WHATSAPP": '3',
    "LABELS": {
        '1': 'Chamada telefônica agendada',
        '2': 'Videochamada agendada por WhatsApp',
        '3': 'Conversa por WhatsApp'
    }
})
export default TipoAtendimentoEnum