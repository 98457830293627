import React, { useState, useRef, useEffect } from 'react'
import moment from 'moment'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import TituloPagina from '../../../componentes/titulo-pagina/tituloPagina';
import ModalDetalheAgendamento from './modalDetalheAgendamento/modalDetalheAgendamento';

import { obterAgendamentos } from '../../../services/agendamento.service';

import './agendamentos.scss'

const Agendamentos = () => {
    const [eventos, setEventos] = useState([])
    const [agendamentos, setAgendamentos] = useState([])
    const [modalDetalheAgendamentoVisivel, setModalDetalheAgendamentoVisivel] = useState(false)
    const [agendamento, setAgendamento] = useState(undefined)

    const calendarRef = useRef()

    useEffect(() => {
        obterAgendamentos()
            .then(res => {
                const eventosRetornados = res.data.data
                const eventosTratados = eventosRetornados.map(item => {
                    const dataInicial = `${item.data} ${item.hora}`
                    const dataFinal = moment(dataInicial).add(30, 'minutes').format('YYYY-MM-DD HH:mm');
                    const itemTratado = { id: item.id, title: item.nome, start: dataInicial, end: dataFinal }
                    return itemTratado
                })
                setEventos(eventosTratados)
                setAgendamentos(eventosRetornados)
            });

    }, [])

    useEffect(() => {
        if (agendamento) {
            setModalDetalheAgendamentoVisivel(true)
        }
    }, [agendamento])

    const onEventClick = (eventInfo) => {
        setAgendamento(agendamentos.find(item => item.id.toString() === eventInfo.event.id))
    }

    const onCloseModalDetalheAgendamento = () => {
        setAgendamento(undefined)
        setModalDetalheAgendamentoVisivel(false)
    }

    const onClickSair = () => {
        localStorage.removeItem('token-tf')
        window.location.reload()
    }

    return (
        <div className="agendamentos-container">
            <ModalDetalheAgendamento open={modalDetalheAgendamentoVisivel} onClose={onCloseModalDetalheAgendamento} agendamento={agendamento} />
            <a href="" className="link-logout" onClick={onClickSair}>Sair</a>
            <TituloPagina>Agendamentos</TituloPagina>


            <div className="calendar-container">
                <FullCalendar
                    height='auto'
                    contentHeight="auto"
                    plugins={[dayGridPlugin, timeGridPlugin]}
                    initialView="timeGridDay"
                    allDaySlot={false}
                    slotMinTime="07:00:00"
                    slotMaxTime="24:00:00"
                    expandRows={true}
                    locale="pt-br"
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                    }}
                    buttonText={{
                        today: 'Hoje',
                        month: 'Mes',
                        week: 'Sem',
                        day: 'Dia'
                    }}
                    weekends={false}
                    events={eventos}
                    ref={calendarRef}
                    eventClick={onEventClick}
                />
            </div>
        </div>
    )
}

export default Agendamentos