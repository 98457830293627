import React from "react";
import { FormControl, MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

import isDC from "../../../types/isDC"

const useStyles = makeStyles({
    root: {
        "& .MuiInputLabel-root.Mui-focused": {
            color: isDC ? '#3f51b5' : '#009739'
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: isDC ? '#3f51b5' : '#009739'
        }
    }
});

const SelectHorario = ({ value, onChangeHorario, error }) => {
    const classes = useStyles();

    return (
        <FormControl variant="outlined" error={error} required={true}>
            <TextField
                labelId="select-horario-label"
                id="select-horario"
                value={value}
                onChange={onChangeHorario}
                label="Horário:"
                variant="outlined"
                className={classes.root}
                select
            >
                <MenuItem value="08:00">08:00</MenuItem>
                <MenuItem value="08:30">08:30</MenuItem>
                <MenuItem value="09:00">09:00</MenuItem>
                <MenuItem value="09:30">09:30</MenuItem>
                <MenuItem value="10:00">10:00</MenuItem>
                <MenuItem value="10:30">10:30</MenuItem>
                <MenuItem value="11:00">11:00</MenuItem>
                <MenuItem value="11:30">11:30</MenuItem>
                <MenuItem value="12:00">12:00</MenuItem>
                <MenuItem value="12:30">12:30</MenuItem>
                <MenuItem value="13:00">13:00</MenuItem>
                <MenuItem value="13:30">13:30</MenuItem>
                <MenuItem value="14:00">14:00</MenuItem>
                <MenuItem value="14:30">14:30</MenuItem>
                <MenuItem value="15:00">15:00</MenuItem>
                <MenuItem value="15:30">15:30</MenuItem>
                <MenuItem value="16:00">16:00</MenuItem>
                <MenuItem value="16:30">16:30</MenuItem>
                <MenuItem value="17:00">17:00</MenuItem>
            </TextField>
        </FormControl>
    )
}

export default SelectHorario