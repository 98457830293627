import React from "react";
import {FormControl, FormLabel, RadioGroup, FormControlLabel, Radio} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import TipoAtendimentoEnum from "../../../enums/tipoAtendimento";

import './inputTipoContato.scss'

import isDC from "../../../types/isDC"

const StyledRadio = withStyles({
    root: {
        color: isDC ? '#FA874B' : '#009739',
        '&$checked': {
            color: isDC ? '#FA874B' : '#009739',
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles({
    root: {
        "&.MuiFormLabel-root.Mui-focused": {
            color: isDC ? '#3f51b5 !important' : '#009739 !important'
        }
    }
});

const InputTipoContato = (props) => {
    const classes = useStyles();
    return (
        <FormControl className="form-control-input-tipo-contato" component="fieldset" error={props.erro}>
            <FormLabel className={classes.root} component="legend">Qual forma de atendimento você prefere?</FormLabel>
            <RadioGroup aria-label="tipo-contato" name="tipo-contato" value={props.value} onChange={props.onChangeTipoContato}>
                <FormControlLabel value={TipoAtendimentoEnum.CHAMADA_TELEFONICA} control={<StyledRadio/>}
                                  label={TipoAtendimentoEnum.LABELS[TipoAtendimentoEnum.CHAMADA_TELEFONICA]}/>
                <FormControlLabel value={TipoAtendimentoEnum.VIDEOCHAMADA_POR_WHATSAPP} control={<StyledRadio/>}
                                  label={TipoAtendimentoEnum.LABELS[TipoAtendimentoEnum.VIDEOCHAMADA_POR_WHATSAPP]}/>
                <FormControlLabel value={TipoAtendimentoEnum.CONVERSA_POR_WHATSAPP} control={<StyledRadio/>}
                                  label={TipoAtendimentoEnum.LABELS[TipoAtendimentoEnum.CONVERSA_POR_WHATSAPP]}/>
            </RadioGroup>
        </FormControl>
    )
}

export default InputTipoContato;