import axios from 'axios'
//import {Agendamento} from "../types/agendamento";

const TOKEN = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoic2VydmljZWNhcmUiLCJjb21wYW55IjoiQ2lhIExhdGlubyBBbWVyaWNhbmEgZGUgTWVkaWNhbWVudG9zIiwic2l0ZSI6Imh0dHBzOlwvXC93d3cuY2xhbWVkLmNvbS5iciIsImlhdCI6MTYxMDk3MTczNiwiZXhwIjoxNzY4NzM4MTM2fQ.jVMWkx_SDZZ5BCyqI_QXIExDxvjJN2J-GNQp7DjGMdo'

export function criarAgendamento(dados) {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*'
    }

    var params = new URLSearchParams();
    params.append('token', TOKEN);
    params.append('type', 'postAgendamento');

    var bodyFormData = new FormData();
    for ( var key in dados ) {
        bodyFormData.append(key, dados[key]);
    }

    return axios({
        method: 'post',
        url: 'https://ws.clamed.com.br:8080/sincroniza.php',
        data: bodyFormData,
        params: params,
        headers: headers
    });
}

export function obterAgendamentos() {
    var params = new URLSearchParams();
    params.append('token', TOKEN);
    params.append('type', 'getAgendamentos');

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*'
    }

    return axios({
        method: 'get',
        url: 'https://ws.clamed.com.br:8080/sincroniza.php',
        params: params,
        headers: headers
    });
}

export function obterAgendamento(id) {
    var params = new URLSearchParams();
    params.append('token', TOKEN);
    params.append('type', 'getAgendamentos');
    params.append('id', id);

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*'
    }

    return axios({
        method: 'get',
        url: 'https://ws.clamed.com.br:8080/sincroniza.php',
        params: params,
        headers: headers
    });
}